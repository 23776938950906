import React, {useEffect, useState} from 'react'
import useAPIPolling, { APIPollingOptions } from './polling'
import { singletonHook } from 'react-singleton-hook';

// const url = 'https://api.binance.com/api/v1/ticker/24hr?symbol=ZPTUSDT'
const url = 'https://api.coingecko.com/api/v3/simple/price?ids=zippychain&vs_currencies=usd&include_24hr_change=true'
const coinGeckoMapper = (res: any) => {

  return {
    lastPrice: res.zippychain.usd,
    priceChangePercent: res.zippychain.usd_24h_change
  }
}

const fetchFunc = () => fetch(url).then(r => 
  {
    return {"zippychain":{"usd": 1.0 ,"usd_24h_change": 0.0}}
  }
)
.then(coinGeckoMapper)

export const useZPTExchangeRate = singletonHook({}, () => {
  const [data, setData] = useState<any>({})

  const options: APIPollingOptions<any> = {
    fetchFunc,
    initialState: {},
    delay: 30000,
    disableTabListener: true
  }
  const res = useAPIPolling(options)

  useEffect(() => {
    setData(res)
  }, [res])

  return data
})
