import React, { useEffect, useState } from "react";
import { Box, Heading, Tab, Tabs, Text } from "grommet";
import { BasePage, BaseContainer } from "src/components/ui";
import { TransactionsTable } from "../../components/tables/TransactionsTable";
import { ApprovalDetails, Filter, RPCTransactionHarmony } from "../../types";
import { useHistory } from "react-router-dom";
import { getTransactions, getCount } from "src/api/client";
import { ShardDropdown } from "src/components/ui/ShardDropdown";
import { useParams } from "react-router-dom";
import { ToolsTab } from "../AddressPage/tabs/tools";
import { ApprovalsRenderer } from "src/components/approvals/approvalsRenderer";
import { HarmonyAddress } from "src/utils";
import { ApiCache } from "src/api/ApiCache";
import { getAllApprovalsForTokens } from "src/api/rpc";
import 'chart.js/auto';


import {Pie as PieChartJs} from "react-chartjs-2";


import { ApprovalsHeader } from "src/components/approvals/approvalsHeader";
import { useERC1155Pool } from "src/hooks/ERC1155_Pool";
import { useERC721Pool } from "src/hooks/ERC721_Pool";
import { useERC20Pool } from "src/hooks/ERC20_Pool";

const ApprovalCache = new ApiCache({ key: "approvalsCache" }); // cache of approvals data mapped


export function ValidatorsPage() {
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState(0);
  const [metamaskAddress, setMetamask] = useState("");
  const [chainId, setChainId] = useState(0);
  const [data, setData] = useState<{miner: string, blkcnt: number}[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedShard, setSelectedShard] = useState("0");

  const isMainNet =
    process.env.REACT_APP_RPC_URL_SHARD0 === "";

  const validChainId = isMainNet
    ? chainId === 1666600000
    : (chainId === 1666700000 || chainId === 1666900000);

  const loadData = async (shardNumber: number) => {

      setIsLoading(false);


    try {
      const resp = await fetch(`https://testnet-rest.zippychain.ai/v0/shard/${shardNumber}/validators/distribution`)
      const result = await resp.json()
      console.log(result)
      setData(result)
    }
    catch (error) {
      // @ts-ignore
      setError(error.message);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadData(Number(selectedShard))
  }, [selectedShard])

  // test user 0x5c57fec3e02e5b64f8c3b47b39942ef682e51459
  return (
    <BaseContainer pad={{ horizontal: "0" }}>
      <Heading size="small" margin={{ bottom: "medium", top: "0" }}>
      Zippy Validators
      </Heading>
      <BasePage pad={"small"} style={{ overflow: "inherit" }}>
        <Box direction="column">
        <ShardDropdown
                  allShardsAvailable={true}
                  selected={selectedShard}
                  onClick={(shardNumber) => setSelectedShard(shardNumber)}
                />
          <Box margin={{ left: "10px", top: "medium" }}>
            <PieChartJs data={
              {
                labels: data.map((e)=>e.miner),
                datasets: [
                  {
                    label: `Validator Distribution on shard ${selectedShard}`,
                    data: data.map((e)=>e.blkcnt),
                  }
                ]
              }
            }
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Chart.js Pie Chart'
                }
              }
            }}
            ></PieChartJs>
          </Box>
        </Box>
      </BasePage>
    </BaseContainer>
  );
}
